function Slider(params) {
    let images = [];
    //let testUrl = 'https://i1.wp.com/digital-photography-school.com/wp-content/uploads/2021/04/panorama-photography-1001-1.jpg?fit=1500%2C375&ssl=1';

    if (params.stationName === 'loski-potok') {
        images.push({src: 'https://postaje.vreme-podnebje.si/images/slider/loskiPotok/image1.jpg', active: 'carousel-item active'})
        images.push({src: '', active: 'carousel-item'})
        images.push({src: '', active: 'carousel-item'})
        images.push({src: '', active: 'carousel-item'})
        images.push({src: '', active: 'carousel-item'})
    }
    else if (params.stationName === 'ribnica') {
        images.push({src: 'https://postaje.vreme-podnebje.si/images/slider/ribnica/image1.jpg', active: 'carousel-item active'})
        images.push({src: 'https://postaje.vreme-podnebje.si/images/slider/ribnica/image2.jpg', active: 'carousel-item'})
        images.push({src: 'https://postaje.vreme-podnebje.si/images/slider/ribnica/image3.jpg', active: 'carousel-item'})
    }
    else if (params.stationName === 'sodrazica') {
        images.push({src: 'https://postaje.vreme-podnebje.si/images/slider/sodrazica/image1.jpg', active: 'carousel-item active'})
        images.push({src: 'https://postaje.vreme-podnebje.si/images/slider/sodrazica/image2.jpg', author: '', active: 'carousel-item'})
        images.push({src: 'https://postaje.vreme-podnebje.si/images/slider/sodrazica/image3.jpg', author: '', active: 'carousel-item'})
    }
    return images.filter(x => x.src.length > 0);
}

export default Slider;