export let News = [
    {
        id: 1,
        title: `Občutna ohladitev in začetek kurilne sezone`,
        content: `Tretji septembrski teden bo zaznamoval izrazit vremenski zasuk. Sprva bo še sončno in poletno toplo. V torek in sredo popoldne se bo živo srebro v najtoplejših krajih na vzhodu države verjetno še zadnjič letos približalo 30 stopinjam Celzija. Sledila bo občutna ohladitev, ki nam jo bo prinesel spust zelo hladnega zraka iznad polarnih predelov. Dnevne temperature se bodo do konca tedna v povprečju spustile za več kot 10 stopinj Celzija, pričakujemo pa lahko tudi obilnejše padavine. Gore bo pobelil sneg.&
        Po prehodu hladne fronte se bo ob koncu tedna k nam širilo območje visokega zračnega tlaka, ki nam bo prineslo kar nekaj sončnih, a tudi zelo svežih dni. V zatišnih legah bo ob jutrih možna slana, čez dan pa bodo temperature od 15 do 20 stopinj Celzija. Predvsem v višje ležečih krajih se bo že začela kurilna sezona.&`,
        day: `12`,
        month: `september`,
        imgUrl: `https://postaje.vreme-podnebje.si/images/news/lastPhoto.jpg`,
        modalImgUrl: null
    },
    {
        id: 2,
        title: `Sneg v septembru`,
        content: `V zadnjih letih imamo sredi septembra pogosto še povsem poletno vreme, v preteklosti pa se je že zgodilo, da je kimavec bolj prikimaval zimskim razmeram.
        Najzgodnejše sneženje v krajih pod 500 metrov nadmorske višine je bilo zabeleženo 11. septembra 1972, ko so snežinke poplesavale na Koroškem.
        Zelo nizko je sredi septembra snežilo tudi leta 1977, ko je na Notranjskem in Kočevskem nad 700 metri nadmorske višine lokalno zapadlo celo več kot 10 centimetrov snega,
        tanka snežna odeja je 17. septembra prekrila tudi Ribnico in Kočevje.&`,
        day: `11`,
        month: `september`,
        imgUrl: `https://postaje.vreme-podnebje.si/images/news/septPhoto.jpg`,
        modalImgUrl: null
    },
    {
        id: 3,
        title: `Končuje se še en vroč dan`,
        content: `Končuje se še en vroč dan, ko smo iskali hladno zavetje. Na Ribniškem in v Loškem Potoku so bile današnje temperature celo med najvišjimi v državi, kar se ne zgodi zelo pogosto:&
        🔥 Ribnica 30,8 °C&
        🔥 Prigorica 30,6 °C&
        🔥 Sodražica 30,3 °C&
        🔥 Gašpinovo 29,5 °C&
        🔥 Retje 29,3 °C&
        🔥 Hrib - Loški Potok 29,2 °C&
        K vročini zelo pripomorejo suha tla, ki se precej bolj segrejejo kot mokra, zato so v krajih s padavinskim primanjkljajem temperature zraka tudi za nekaj stopinj višje kot v preostalem delu Slovenije na podobni nadmorski višini. V prihodnjih dneh nam bo še bolj vroče, obilnejšega dežja, ki bi končal sušo, pa vsaj do začetka julija zelo verjetno ne bomo deležni.&`,
        day: `19`,
        month: `junij`,
        imgUrl: `https://postaje.vreme-podnebje.si/images/news/vroce.jpeg`,
        modalImgUrl: null
    },
]

export default News;