<template>
  <div class="container mt-4" v-if="$route.params.stationName != 'sodrazica'">
    <div class="row">
      <div class="col-12 col-xl-12">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.SLIDESHOW"
          widgetTitle=""
          widgetIcon="images"
          :showWidgetTitle="false"
          :GetSliderImageData="sliderData"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xl-6 col-lg-6 widget">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.STATION"
          :widgetTitle="GetSodrazicaTitleDate"
          widgetIcon="star"
          :showWidgetTitle="true"
          :GetTableData="GetTableData"
        />
      </div>
      <div class="col-12 col-xl-6 col-lg-6">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.FORECAST"
          widgetTitle="Slikovna vremenska napoved"
          widgetIcon="bookmark"
          :showWidgetTitle="true"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xl-6 col-lg-6 widget">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.NEWS"
          widgetTitle="Aktualno"
          widgetIcon="newspaper"
          :showWidgetTitle="true"
        />
      </div>
      <div class="col-12 col-lg-6 col-xl-6">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.CHART"
          widgetTitle="Graf gibanja temperature"
          widgetIcon="chart-line"
          :GetChartOptions="GetChartOptions"
          :GetMaxStatsData="GetRecordsDayData"
          :showWidgetTitle="true"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-xl-6 col-lg-6 widget">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.WEBCAM"
          widgetTitle="Radarska slika padavin (vir: ARSO)"
          widgetIcon="video"
          :showWidgetTitle="true"
        />
      </div>
      <div class="col-12 col-lg-6 co-xl-6">
        <Widget
          :widgetStatusEnum="WidgetStatusEnum.SPONSOR"
          widgetIcon="donate"
          :widgetTitle="cameraTitle"
          :showWidgetTitle="true"
        />
      </div>
    </div>
  </div>
  <SodrazicaTemplate
    :cameraTitle="cameraTitle"
    :GetChartOptions="GetChartOptions"
    :GetRecordsDayData="GetRecordsDayData"
    :GetSodrazicaTitleDate="GetSodrazicaTitleDate"
    :GetTableData="GetTableData"
    :sliderData="sliderData"
    :WidgetStatusEnum="WidgetStatusEnum"
    v-else
  />
</template>

<script>
import Widget from "../components/Widget/Widget.vue";
import { WidgetStatusEnum } from "../Enums/index";
import Slider from "../Utils/Slider/Slider";
import SodrazicaTemplate from "../components/Template/SodrazicaTemplate.vue";

export default {
  name: "Overview",
  components: {
    Widget,
    SodrazicaTemplate,
  },
  data() {
    return {
      WidgetStatusEnum: WidgetStatusEnum,
      sliderData: null,
      widgetTitle: "Vremenski podatki",
      cameraTitle: "",
    };
  },
  computed: {
    GetChartOptions() {
      return this.$store.getters.chartOptions;
    },
    GetTableData() {
      return this.$store.getters.stationList;
    },
    GetRecordsDayData() {
      return this.$store.getters.recordsDayData;
    },
    GetSodrazicaTitleDate() {
      let title = this.$store.getters.getSodrazicaTitleDate;
      if (title != null) {
        return `Vremenski podatki (osveženo ${title})`;
      } else {
        return "Vremenski podatki";
      }
    },
  },
  created() {
    this.$store.dispatch("getWidgetData");
  },
  /*
  watch: {
    $route() {
      this.$store.dispatch("getWidgetData");
    },
  },
  */
  mounted() {
    this.sliderData = Slider(this.$route.params);
    if (this.$route.params.stationName == "sodrazica") {
      this.cameraTitle = "Spletna kamera Izver";
    } else if (this.$route.params.stationName == "ribnica") {
      this.cameraTitle = "Spletna kamera Prigorica";
    } else if (this.$route.params.stationName == "loski-potok") {
      this.cameraTitle = "Spletna kamera Hrib - Loški Potok";
    }
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .widget {
    margin-bottom: 15px;
  }
}

.row:before,
.row:after {
  display: none !important;
}
</style>